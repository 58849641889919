<template>
  <ComingSoon />
</template>

<script>
import ComingSoon from '@/components/ComingSoon.vue'

export default {
  name: 'About',

  components: {
    ComingSoon,
  }
}
</script>
