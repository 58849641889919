<template>
  <div class="hero min-h-screen bg-base-200">
  <div class="hero min-h-screen bg-base-200" :style="bgColor">
    <div class="flex-col justify-center hero-content lg:flex-row">
      <div class="text-center lg:text-left">
        <h1 class="mb-5 text-4xl font-bold">
          {{ message }}
        </h1>
        <p class="text-2xl">In the mean time, why not play with some colors?</p>
        <div class="flex items-center p-10 bg-base-300 rounded-md">
          <button class="flex-auto btn text-center mr-5 bg-red-400 overflow-hidden rounded-full" :style="bgColor" @click="pick">
            Pick a Color
            <input id="color" type="color" class="invisible" v-model="color" @change="changeBG" ref="colorPicker">
          </button>
          <button class="flex-auto btn btn-info rounded-full" @click="bgColor = ''">Reset</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ComingSoon',

  data() {
    return {
      message: 'Page Under Construction',
      color: '',
      bgColor: '',
    };
  },

  methods: {
    pick() {
      this.$refs.colorPicker.click()
    },

    changeBG() {
      this.bgColor = `background-color: ${this.color} !important`
    }
  }
}
</script>
